import React from "react";
import config from "../config"

//PROD
export const URL_SOCKET = config.EMPRESA === "multdesk" ? "https://websocket.juriszap.com" : "https://websocket.zapresponder.com.br";
export const URL = config.EMPRESA === "multdesk" ? "https://api.juriszap.com" : "https://api.zapresponder.com.br";
export const URL_STORAGE = config.EMPRESA === "multdesk" ? "https://storage.juriszap.com" : "https://storage.zapresponder.com.br";

export const URL_CHAT = config.EMPRESA === "multdesk" ? "https://chat.juriszap.com" : "https://chat.zapresponder.com.br";
export const URL_TUTORIAL="https://zapresponder.com.br/videos-ajuda"

/// HML
// export const URL_SOCKET = "https://hmlwebsocket.multdesk.com.br";
// export const URL = "https://hmlapi.multdesk.com.br";

/// LOCAL
// export const URL_SOCKET = "http://localhost:3002";
// export const URL = "http://localhost:3001";
